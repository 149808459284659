/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'

const MyCoursesNoCourse: FC = () => {
    const intl = useIntl()

    return (
        <>
            <div className='row landing noCourse'>
                <div className='order-1 order-lg-0 col-lg-7 d-flex flex-column justify-content-center'>
                    <h1 className='text-center text-lg-start'>
                    {intl.formatMessage({ id: 'POPLEADS.NO_COURSE' })}
                    </h1>
                    <p className='text-center text-lg-start'>
                    {intl.formatMessage({ id: 'POPLEADS.NO_COURSE_INFO' })}
                      </p>
                    <Link className='mx-auto mx-lg-0' to="/"><button>{intl.formatMessage({ id: 'POPLEADS.NO_COURSE_INFO_BUTTON' })}</button></Link>
                </div>
                <div className='order-0 order-lg-1 col-lg-5 my-6 d-flex justify-content-center'>
                    <img
                        id='illusRight'
                        alt=''
                        src={toAbsoluteUrl('/media/illustrations/noCourse.png')}
                    />
                </div>
            </div>
        </>
    )
}

export { MyCoursesNoCourse }
