import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
// import { MenuTestPage } from '../pages/MenuTestPage'
import { Home } from "../popleads/pages/home"
import { MyCourseDetail } from "../popleads/pages/myCourseDetail"
import { MyCourses } from '../popleads/pages/myCourses'
import { MyAccount } from '../popleads/components/myAccount'

export function PrivateRoutes() {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/courses/detail/:id' exact component={MyCourseDetail} />
        <Route path='/my-courses/' exact component={MyCourses} />
        <Route path='/my-courses/detail/:id' exact component={MyCourseDetail} />
        <Route path='/my-account' exact component={MyAccount} />
        {/*<Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
