/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CourseBox } from "../components/courseBox";
import { MyCoursesNavbar } from '../components/myCoursesNavbar';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { UserModel } from '../../modules/auth/models/UserModel';
import { getDynamicValue } from '../functions/util';
import { MyCoursesNoCourse } from './myCoursesNoCourse';
import clsx from 'clsx';
// import { KTSVG } from '../../../_metronic/helpers/components/KTSVG';
// import { Landing } from "./landing";


interface MyCoursesProps {
    campaignCurrentList?: any
    campaignHistoryList?: number
}

const MyCoursesWithCourses: FC<MyCoursesProps> = (props: MyCoursesProps) => {
    const intl = useIntl()
    const [navbar, setNavbar] = useState("activeCourses");
    const [courses, setCourses] = useState<any>([]);

    useEffect(() => {
        setCourseLMSProgress(props.campaignCurrentList);
        setCourses(props.campaignCurrentList);
    }, [])
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;

    const setCourseLMSProgress = (campaigns: any) => {
        if (user.profile.etiket) {
            for (const course of campaigns) {
                for (const etiket of user.profile.etiket) {
                    if (etiket.campaignId === course.id) {
                        course["lmsProgress"] = +etiket.lmsProgress.split('%')[0];
                    }
                }
            }
        }
    }

    const navBarChange = (navBar: any) => {
        if (navBar === 'activeCourses') {
            setCourseLMSProgress(props.campaignCurrentList);
            setCourses(props.campaignCurrentList);
        } else {
            setCourseLMSProgress(props.campaignHistoryList);
            setCourses(props.campaignHistoryList);
        }
        setNavbar(navBar);
    }

    return (<>


        <div className='row allCourses'>
            <MyCoursesNavbar navbar={navbar} setNavbar={(returnNavbar: any) => navBarChange(returnNavbar)} />
            {/*<div className='d-flex w-100'>
                <div className='allCoursesIcons myCourses'>
                    <span>
                        <KTSVG
                            path='/media/svg/popleads/grid.svg'
                            className='svg-icon-1 grid'
                        />
                    </span>
                    <span>
                        <KTSVG
                            path='/media/svg/popleads/list.svg'
                            className='svg-icon-1'
                        />
                    </span>
                    <select>
                        <option>Default Sorting</option>
                    </select>
                </div>
                
            </div>*/}
            <div className={clsx('allCoursesGrid myCoursesGridTop',courses.length === 0 ? 'noCourse' : '' )}>
                {courses.length > 0 ?
                    courses.map((course: any) =>
                        <CourseBox
                            key={course.id}
                            title={course.ad}
                            subtitle={getDynamicValue(course.columnsList, 'Short Description')}
                            hour={getDynamicValue(course.columnsList, 'Video Hours')}
                            lesson={getDynamicValue(course.columnsList, 'Number of Lectures')}
                            primary={course.basTarih !== null}
                            primaryText={intl.formatMessage({ id: 'POPLEADS_RESUME_COURSE' })}
                            progressValue={course.lmsProgress}
                            imgUrl={course.imgURL}
                            id={course.id}
                            isCompleted={navbar === 'completed'}
                            completedText={intl.formatMessage({ id: 'POPLEADS_COURSE_COMPLETED' })}
                        />
                    )
                    :
                    <MyCoursesNoCourse />
                }
            </div>
        </div>
    </>
    )
}

export { MyCoursesWithCourses }

