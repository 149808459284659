/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {ScrollTop} from '../../_metronic/layout/components/ScrollTop'
import {ChangePassword} from '../popleads/components/changePassword'
import {UserModel} from '../modules/auth/models/UserModel'

const Routes: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <Switch>
        {/* Render auth page when user at `/auth` and not authorized. */}
        {!user ? (
          <Route>
            <AuthPage />
          </Route>
        ) : user.isRegistrationComplete !== 1 ? (
          /* If user didn't change the initial password given by email, then force him to change password */
          <Redirect from='/auth' to='/change-password' />
        ) : (
          /* If authorized & initial password changed, redirect to home page */
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!user ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to='/auth/login' />
        ) : user.isRegistrationComplete !== 1 ? (
          /* If user didn't change the first password given by email, then force him to change password */
          <MasterLayout>
            <Switch>
              <Route path='/change-password' exact component={ChangePassword} />
              <Redirect from='*' to='/change-password' />
            </Switch>
          </MasterLayout>
        ) : (
          /* If authorized & initial password changed, enable all routes */
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        )}
      </Switch>

      <ScrollTop />
      <MasterInit />
    </>
  )
}

export {Routes}
