/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { DefaultTitle } from '../../../_metronic/layout/components/header/page-title/DefaultTitle'
import { CoursePreview } from '../components/coursePreview'
import { MyCourseDetailNavbar } from '../components/myCourseDetailNavbar'
import { MyCourseDetailOverview } from '../components/myCourseDetailOverview'
import { Discussions } from '../components/discussions'
import { Certificates } from '../components/certificates'
import { Redirect, useParams } from "react-router-dom";
import { Loading } from '../partials/loading'
import { Error } from '../partials/error';
import { post } from '../networking/requestService'
import { UserModel } from '../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { getDynamicValue } from '../functions/util';

const MyCourseDetail: FC = () => {
    const intl = useIntl()
    const [navbar, setNavbar] = useState('overview');
    const { id } = useParams<any>();
    const [course, setCourse] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState("");
    const [displayNavBar, setDisplayNavBar] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
    const breadCrumbs: Array<PageLink> = [
        {
            title: 'My Courses',
            path: '/my-courses',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    useEffect(() => {
        setCourse((prevObj: any) => {
            if (!prevObj) return prevObj;
            let obj = { ...prevObj }
            obj.isEnrolled = checkIsEnrolled(obj.campaign);
            // console.log(obj);
            return obj;
        });
    }, [user]);

    const getCampaign = () => {
        const requestObj = {
            fn: "getCampaign",
            campaignId: id,
            certificateRequest: 1,
            isCoursePreview: 1,
            isDetailPage: 1
        }
        post(requestObj)
            .then(({ data: { campaign } }) => {
                setInfo(JSON.parse(getDynamicValue(campaign.columnsList, 'Info')));
                setCourse({ campaign, isEnrolled: checkIsEnrolled(campaign) });
                /*if (campaign.lmsUnits) {
                    let lmsData = JSON.parse(campaign.lmsUnits);
                    if (lmsData.downloadable === 'downloadable') setDisplayNavBar(true);
                }*/

                for (var i = 0; i < user.profile.etiket.length; i++) {
                    const obj = user.profile.etiket[i];

                    if (obj.campaignId === parseInt(id) && obj.isQualifyForCertificate === 1 && obj.lmsStatus === "Completed" && obj.completionDate) {
                        setDisplayNavBar(true);
                    }
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError({ text: err.response.data.text, code: err.response.status });
                setLoading(false);
            });
    }

    useEffect(() => {
        getCampaign();
    }, [id]);


    const checkIsEnrolled = (campaign: any) => {
        let isEnrolled = false;
        if (user && user.profile && user.profile.etiket) {
            for (const etiket of user.profile.etiket) {
                if (etiket.campaignId === campaign.id) {
                    isEnrolled = true;
                    setRedirect(true);
                    break;
                }
            }
        }
        return isEnrolled;
    }

    // console.log("redirect: ", redirect);
    return (
        <>
            {redirect && window.location.pathname.includes("/courses") ? <Redirect to={'/my-courses/detail/' + id} /> :
                <>
                    {loading ? <Loading />
                        :
                        error ? <Error text={error.text} code={error.code} />
                            :
                            <div className='pageContainer'>
                                <PageTitle breadcrumbs={breadCrumbs}>{course.campaign.ad}</PageTitle>
                                <DefaultTitle />
                                <CoursePreview course={course} getCampaign={getCampaign} setDisplayNavBar={(bool:any) => setDisplayNavBar(bool)} />
                                {displayNavBar &&
                                    <MyCourseDetailNavbar navbar={navbar} setNavbar={(returnNavbar: any) => setNavbar(returnNavbar)} />
                                }
                                <div className={('container p-0' + (displayNavBar ? ' course-detail-content' : ''))}>
                                    {navbar === "overview" && <MyCourseDetailOverview info={info}/>}
                                    {/*navbar === "discussion" && <Discussions />*/}
                                    {navbar === "certificates" && <Certificates course={course} />}
                                </div>
                            </div>
                    }
                </>
            }
        </>
    )
}

export { MyCourseDetail }
