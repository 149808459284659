/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { post } from "../../../popleads/networking/requestService"
import { createCookie } from "../../../popleads/functions/util"
import { login } from '../redux/AuthCRUD'



const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const [passwordShown, setPasswordShown] = useState(false)
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.VALIDATION.WRONG_EMAIL' }))
      .min(3, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_3' }))
      .max(50, intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_50' }))
      .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_EMAIL' })),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_8' }))
      .max(50, intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_50' }))
      .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_PASSWORD' })),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      const requestObj = {
        "fn": "mailLogin",
        "email": values.email,
        "password": values.password,
        "appType": "HR"
      };
      post(requestObj)
        .then(({ data: { sid, data } }) => {
          //createCookie("XPIDER_SID", sid, 14);
          localStorage.setItem('sid', sid);
          // console.log("data.user: ", data.user)
          dispatch(auth.actions.login(sid, data.user))
        })
        .catch((error) => {
          setLoading(false)
          setSubmitting(false)
          //console.log("error: ", error.response)
          setStatus(error.response.data.text)
        })

      /*setTimeout(() => {
         login(values.email, values.password)
           .then(({data: {accessToken}}) => {
             setLoading(false)
             dispatch(auth.actions.login(accessToken))
           })
           .catch(() => {
             setLoading(false)
             setSubmitting(false)
             setStatus('The login detail is incorrect')
           })
       }, 1000)*/
    },
  })
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const isRTL = document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text mb-10'>
        <h1 className='text mb-3 title-login-form'> {intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container mb-3'>
            <span className='error-alert' role='alert'>
              {formik.errors.email}
            </span>
          </div>
        )}
        <input
          id='input-login'
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span className='error-alert' role='alert'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
        <div className={clsx(
          'd-flex login-field',
          {
            'is-invalid': formik.touched.password && formik.errors.password,
          },
          {
            'is-valid': formik.touched.password && !formik.errors.password,
          }
        )}
        >

          <input
            id='input-login'
            type={passwordShown ? 'text' : 'password'}
            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
            autoComplete='off'
            style={{ position: 'relative' }}
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {passwordShown ? (
            <svg className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')} onClick={togglePassword} xmlns='http://www.w3.org/2000/svg' width='23.708' height='23.708' viewBox='0 0 23.708 23.708'>
              <g id='Icon_feather-eye-off' data-name='Icon feather-eye-off' transform='translate(-0.086 -0.086)'>
                <path id='Path_154' data-name='Path 154' d='M17.578,19.233a9.556,9.556,0,0,1-5.638,1.956C5.3,21.189,1.5,13.594,1.5,13.594A17.514,17.514,0,0,1,6.3,7.956M9.947,6.228A8.654,8.654,0,0,1,11.94,6c6.644,0,10.44,7.594,10.44,7.594a17.562,17.562,0,0,1-2.05,3.028m-6.378-1.016a2.848,2.848,0,1,1-4.024-4.025' transform='translate(0 -1.654)' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                <path id='Path_155' data-name='Path 155' d='M1.5,1.5,22.38,22.38' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
              </g>
            </svg>
          ) : (
            <svg className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')} onClick={togglePassword} xmlns='http://www.w3.org/2000/svg' width='22.884' height='17.188' viewBox='0 0 22.884 17.188'>
              <g id='Icon_feather-eye' data-name='Icon feather-eye' transform='translate(-0.5 -5)'>
                <path id='Path_149' data-name='Path 149' d='M1.5,13.594S5.3,6,11.942,6s10.442,7.594,10.442,7.594-3.8,7.594-10.442,7.594S1.5,13.594,1.5,13.594Z' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                <path id='Path_150' data-name='Path 150' d='M19.2,16.348A2.848,2.848,0,1,1,16.348,13.5,2.848,2.848,0,0,1,19.2,16.348Z' transform='translate(-4.406 -2.754)' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
              </g>
            </svg>
          )}
        </div>

      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-flex justify-content-between buttons-div'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-small  w-100 mb-5'
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block', color: 'white' }}>
              {intl.formatMessage({ id: 'POPLEADS.PLEASE_WAIT' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link
          to='/auth/forgot-password'
          className='link-primary fs-6 fw-bolder forgot-password-text'
        >
          {intl.formatMessage({ id: 'AUTH.GENERAL.FORGOT_BUTTON' })}
        </Link>
      </div>
      {/* end::Action */}
    </form>
  )
}
