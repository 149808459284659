/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

const Landing: FC = () => {
    const intl = useIntl()
    const html = document.getElementsByTagName("html")[0]
    const isRTL = html.getAttribute("dir") === "rtl"

    return (
        <>
            <div className='row landing justify-content-between align-items-center'>
                <div className='col-lg-7 col-xl-6 d-flex flex-column justify-content-center'>
                    <h1 className='mx-auto mx-lg-0'>
                        {/* <div className='d-flex'>
                            <span className='wel-text me-3 me-lg-5'>{intl.formatMessage({ id: 'POPLEADS.WELCOME_TO' })}</span>
                            <img     
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logos/xxx.png')}
                                className='logo d-none d-lg-inline mb-3'
                                width="140"
                            />
                            <span className='d-inline d-lg-none'> MaSa </span>
                        </div> */}
                        <span>{intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM_N' })}</span>
                    </h1>
                    <p className="login-gray-message mt-5 text-center text-lg-start">{intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM_HOME' })}</p>

                    <div className='marketIcons'>
                        <a href='https://play.google.com/store/apps/details?id=com.popleads.ta3leembusiness&hl=en_SG&gl=US' target="_blank">
                            {isRTL ?
                                <KTSVG
                                    path='/media/svg/popleads/googlePlay_RTL.svg'
                                    className='marketIcon'
                                />
                                :
                                <KTSVG
                                    path='/media/svg/popleads/googlePlay.svg'
                                    className='marketIcon'
                                />
                            }
                        </a>
                        <a href="https://apps.apple.com/us/app/ta3leem-for-business/id1500897240" target="_blank">
                            {isRTL ?
                                <KTSVG
                                    path='/media/svg/popleads/appStore_RTL.svg'
                                    className='marketIcon'
                                />
                                :
                                <KTSVG
                                    path='/media/svg/popleads/appStore.svg'
                                    className='marketIcon'
                                />
                            }

                        </a>
                    </div>
                </div>
                <div className='col-lg-5 p-6 d-flex justify-content-center'>
                    <img
                        id='illusRight'
                        alt=''
                        src={toAbsoluteUrl('/media/illustrations/homepage.png')}
                        className='mt-5 mt-lg-0'
                    />
                </div>
            </div>

        </>
    )
}

export { Landing }
