import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'

type Props = {
  basename: string
}

const root = document.documentElement;
root?.style.setProperty("--background-color", "#FAFAFA");
root?.style.setProperty("--primary-text-color", "#2C2C2C");
root?.style.setProperty("--landing-text-color", "#191919");
root?.style.setProperty("--all-courses-section-title-color", "#FAFAFA");
root?.style.setProperty("--all-courses-section-title-background-color", "#191919");
root?.style.setProperty("--all-courses-grid-item-background-color", "#FFFFFF");
root?.style.setProperty("--all-courses-info-color", "#8E9AA0");
root?.style.setProperty("--all-courses-item-subtitle-color", "#8E9AA0");
root?.style.setProperty("--all-courses-item-title-color", "#1D252D");
root?.style.setProperty("--all-courses-item-button-background-color", "#FFFFFF");
root?.style.setProperty("--all-courses-item-button-color", "#C1C1C1");
root?.style.setProperty("--all-courses-item-button-primary-background-color", "#E53422");
root?.style.setProperty("--all-courses-item-button-primary-color", "#FFFFFF");
root?.style.setProperty("--footer-background-color", "#191919");
root?.style.setProperty("--footer-color", "#FAFAFA");
root?.style.setProperty("--scrollTop-background-color", "#8E9AA0");
root?.style.setProperty("--scrollTop-color", "#FFFFFF");
root?.style.setProperty("--selected-menu-item-border-color", "#E52322");
root?.style.setProperty("--selected-navbar-item-border-color", "#FAFAFA");
root?.style.setProperty("--header-menu-item-color", "#8E9AA0");
root?.style.setProperty("--header-selected-menu-item-color", "#1D252D");
root?.style.setProperty("--myCourse-subjects-background-color", "#394755");
root?.style.setProperty("--myCourse-subjects-items-background-color", "#4C5B69");
root?.style.setProperty("--myCourse-subjects-color", "#FAFAFA");
root?.style.setProperty("--primary-button-background-color", "#E52322");
root?.style.setProperty("--navbar-myCourses-background-color", "#191919");
root?.style.setProperty("--navbar-myCourses-color", "rgba(255, 255, 255, 0.65)");
root?.style.setProperty("--navbar-myCourses-selected-color", "rgb(255, 255, 255)");
root?.style.setProperty("--faq-background-color", "#FFFFFF");
root?.style.setProperty("--faq-button-color", "#191919");
root?.style.setProperty("--faq-text-color", "#2C2C2C");
root?.style.setProperty("--certificate-button-background-color", "#FFFFFF");
root?.style.setProperty("--certificate-button-color", "#8E9AA0");
root?.style.setProperty("--no-course-text-color", "#2C2C2C");
root?.style.setProperty("--searchBox-inputs", "#FFFFFF");
root?.style.setProperty("--Icon_feather-play", "#FAFAFA");
root?.style.setProperty("--scrollbar-color", "#E52322");
root?.style.setProperty("--explore-button-bg-color", "#E52322");
root?.style.setProperty("--explore-button-text-color", "#FAFAFA");
root?.style.setProperty("--progress-empty-color", "#C1C1C1");
root?.style.setProperty("--progress-fill-color", "#2C2C2C");
root?.style.setProperty("--login-box-color", "#FFFFFF");
root?.style.setProperty("--login-welcome-message", "#2C2C2C");
root?.style.setProperty("--login-box-shadow-color", "#1D252D0D");
root?.style.setProperty("--input-border-color", "#FAFAFA");
root?.style.setProperty("--input-invalid-border-color", "#E52322");
root?.style.setProperty("--secondary-text-color", "#8E9AA0");




const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
